import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import ProjectsService from "src/services/ProjectsService";
import {Button, FormControl, FormLabel, Textarea, Input, Tooltip} from '@chakra-ui/react';
import {AddIcon, ArrowBackIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import Banner from "src/components/shared/UIShell/Banner/Banner";

import SortableList from "src/components/shared/SortableList/SortableList";
import DynamicInput from "src/components/shared/DynamicInput/DynamicInput";
import {v4 as uuidv4} from "uuid";

import './ProjectsView.scss';

const ProjectsNewView = () => {
    const history = useHistory();
    const [descriptionState, setDescription] = useState([{uuid: uuidv4(), content: '', id: 1}]);
    // const [items, setItems] = useState(['1', '2', '3']);

    const init_project = {
        title: "",
        description: ""
    };
    const [newProject, setNewProject] = useState(init_project);

    const postProjectToAPI = async (event) => {
        event.preventDefault();

        let mergedDescription = "";
        for (let i = 0; i < descriptionState.length; i++){
            // console.log('Content = ' +  descriptionState[i].content + '  ');
            mergedDescription += descriptionState[i].content + '#$%^#';
        }
        // await setNewProject({...newProject, description: mergedDescription});
        // console.log(newProject);

        const response = await ProjectsService.postProjectToAPI({...newProject, description: mergedDescription});
        console.log('Response  ' + response);
        if (response.status === 201){
            setNewProject(init_project);
            history.push('/dashboard/projects');
        }
        else{
            console.log(response);
        }
    };

    return (
        <div>
            <Banner heading='Create a new Project'>
                <Button leftIcon={<ArrowBackIcon />}
                        variant="solid"
                        onClick={() => history.goBack()}
                >
                    Back To Projects
                </Button>
            </Banner>

            <div className="projects__content-body">
                <div className="projects__content-body-left">
                    <form onSubmit={postProjectToAPI}>
                        <FormControl id="project" >
                            <FormLabel>Project Title</FormLabel>
                            <Input type="text"
                                   placeholder="Project title"
                                   value={newProject.title}
                                   onChange={(e) => {
                                       setNewProject({ ...newProject, title: e.target.value });
                                    }
                                   }
                            />
                            {/*<FormHelperText>We'll never share your email.</FormHelperText>*/}
                            <FormLabel>Project Description</FormLabel>
                            <DynamicInput parentState={descriptionState} onChange={(newDescription) => setDescription(newDescription)}/>

                            {/*<Textarea*/}
                            {/*    placeholder="Project description"*/}
                            {/*    value={newProject.description}*/}
                            {/*    onChange={(e) => {*/}
                            {/*        setNewProject({ ...newProject, description: e.target.value });*/}
                            {/*    }*/}
                            {/*    }*/}
                            {/*/>*/}

                            <Button type="submit" isFullWidth={true} colorScheme="teal">Create Project</Button>
                        </FormControl>
                    </form>
                </div>
                <div className="projects__content-body-right">
                    <span className="projects__ordering_preview_container">
                        <FormLabel>Ordering Preview</FormLabel>
                        <Tooltip label="Drag to reorder description lines. Animations may not work yet." fontSize="sm">
                            <InfoOutlineIcon />
                        </Tooltip>
                    </span>
                    <SortableList parentState={descriptionState} onChange={(newDescription) => setDescription(newDescription)} />
                </div>
            </div>
        </div>
    )
};

export default ProjectsNewView;