import {useRouteMatch} from "react-router-dom";
import React from "react";
import { Link } from "@chakra-ui/react"
import { Link as ReactRouterLink } from "react-router-dom";

import './SidebarOption.scss';


const SidebarOption = ({ title, Icon, link="" }) => {
    let { path, url } = useRouteMatch();

    return (
        <Link as={ReactRouterLink} className="sidebarOption" to={`${url}/` + link}>
            <span className="sidebarOption__container">
                {Icon && <Icon className="sidebarOption__icon" />}
                {title}
            </span>

        </Link>
    )
}

export default SidebarOption;