import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Heading } from "@chakra-ui/react"

import {HamburgerIcon, AddIcon, SettingsIcon, CalendarIcon, StarIcon} from '@chakra-ui/icons'
import SidebarOption from "src/components/shared/UIShell/Sidebar/SidebarOption";

import './Sidebar.scss';

function Sidebar() {
    let history = useHistory();

    return (
        <div className="sidebar">
            <div
                className="sidebar__option_container"
            >
                {/*<div className="sidebar__option">*/}
                {/*    <SidebarOption title="Home" Icon={PhoneIcon} link="/"> </SidebarOption>*/}
                {/*</div>*/}
                <div className="sidebar__option">
                    <SidebarOption title="Resume" Icon={HamburgerIcon} link="resume"> </SidebarOption>
                </div>
                <div className="sidebar__option">
                    <SidebarOption title="Projects" Icon={CalendarIcon} link="projects"> </SidebarOption>
                </div>
                <div className="sidebar__option">
                    <SidebarOption title="Experiences" Icon={SettingsIcon} link="experiences"> </SidebarOption>
                </div>
                <div className="sidebar__option">
                    <SidebarOption title="Qualifications" Icon={StarIcon} link="qualifications"> </SidebarOption>
                </div>
                <div className="sidebar__option">
                    <SidebarOption title="Skills" Icon={AddIcon} link="skills"> </SidebarOption>
                </div>
                {/*<div className="sidebar__option">*/}
                {/*    <SidebarOption title="Cover Letter" Icon={WarningIcon} link="cover_letter"> </SidebarOption>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default Sidebar
