import React, {useState} from 'react';
import {Input} from '@chakra-ui/react';
import { IconButton } from "@chakra-ui/react";
import { MinusIcon, AddIcon } from "@chakra-ui/icons"
import { v4 as uuidv4 } from 'uuid';
import {Button} from '@chakra-ui/react';

import './DynamicInput.scss';

const DynamicInput = (props) => {
    const [counter, setCounter] = useState(1);
    const tempArray = [
        {uuid: uuidv4(), content: '', id: counter}];

    const [dataArray, setDataArray] = useState(tempArray);

    const contentOnAddItem = (event) => {
        event.preventDefault();
        const newID = uuidv4();
        // dispatch(createAction({ id: newID, content: "" }));
        const newArray = [...props.parentState, { uuid: newID, content: "", id: counter+1 }];
        setCounter(counter+1);
        props.onChange(newArray);
    };

    const contentOnDeleteItem = (uuid) => (event) => {
        event.preventDefault();
        console.log('on delete');
        // const datakey = event.target.getAttribute('data-key');

        let tempArray = [...props.parentState].filter(item => {
            return item.uuid !== uuid;
        });
        // console.log(datakey);
        console.log(tempArray);

        // setDataArray(tempArray);
        props.onChange(tempArray);
    };

    const contentOnChange = (uuid) => (event) => {
        event.preventDefault();
        const newArray = props.parentState.map((item) => {
            if (uuid !== item.uuid) return item;
            return {...item, content: event.target.value}
        });

        props.onChange(newArray);
    };

    return(
        <div>
            {props.parentState.map((item, index, array) => {
                if (index === array.length - 1){
                    return (
                        <div key={item.id} className="dynamicinput__item">
                            <Input placeholder="Describe your project here" className="dynamicinput__field" value={item.content} onChange={contentOnChange(item.uuid)} />
                            <IconButton
                                colorScheme="teal"
                                aria-label="Add description line"
                                icon={<AddIcon />}
                                onClick={contentOnAddItem}
                            />
                        </div>
                    )
                }
                return (
                    <div key={item.id} className="dynamicinput__item">
                        <Input placeholder="Describe your project here" className="dynamicinput__field" value={item.content} onChange={contentOnChange(item.uuid)}/>
                        <IconButton
                            colorScheme="red"
                            aria-label="Add description line"
                            icon={<MinusIcon />}
                            onClick={contentOnDeleteItem(item.uuid)}
                        />
                    </div>
                )
            })}
            {/*<Button colorScheme="teal"*/}
            {/*    onClick={contentOnAddItem}>*/}
            {/*    Add Description Line*/}
            {/*</Button>*/}
        </div>
    )
};

export default DynamicInput