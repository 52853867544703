import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import RegisterService from '../../../services/RegisterService.js';

import {Button, VStack, Heading, Link, Input, Box} from '@chakra-ui/react';
import { WarningTwoIcon } from '@chakra-ui/icons'
import './Register.css';
import { useToast } from "@chakra-ui/react";

const RegisterView = () => {
    let history = useHistory();
    let toast = useToast();
    const [signUpObj, setSignUpObj] = useState({
        username: "",
        email: "",
        password: "",
        confirm_password: ""
    });

    const signUp = async (event) => {
        event.preventDefault();
        console.log('signup');
        const validationStatus = RegisterService.validateRegisterForm(signUpObj);
        console.log(validationStatus);

        if (validationStatus){
            const response = await RegisterService.signUp(signUpObj);
            if (response.status === 201) history.push("/login");
        }
        else{
            toast({
                title: "Error signing up",
                description: "Make sure username and password are at least 8 characters.",
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
    };

    return (
        <div className="register">
            <Heading className="register__heading"> Sign Up </Heading>

            <form>
                <VStack
                    spacing={4}
                    align="stretch"
                >

                <Box>
                <label> Username </label>
                <Input
                    type="text"
                    name="username"
                    value={signUpObj.username}
                    onChange={(e) => setSignUpObj({ ...signUpObj, username: e.target.value })}
                />
                </Box>

                <Box>
                <label> Email </label>
                <Input
                    type="text"
                    name="email"
                    value={signUpObj.email}
                    onChange={(e) => setSignUpObj({ ...signUpObj, email: e.target.value })} />
                </Box>

                <Box>
                <label> Password </label>
                <Input
                    type="password"
                    name="password"
                    value={signUpObj.password}
                    onChange={(e) => setSignUpObj({ ...signUpObj, password: e.target.value })} />
                </Box>

                <Box>
                <label> Confirm Password </label>
                <Input
                    type="password"
                    name="confirm_password"
                    value={signUpObj.confirm_password}
                    onChange={(e) => setSignUpObj({ ...signUpObj, confirm_password: e.target.value })} />
                </Box>

                <Button colorScheme="teal" isFullWidth={true} onClick={signUp} type="submit" value="Submit"> Sign Up! </Button>
                </VStack>
            </form>
            <Link className="register__login_link" color="teal.500" size="xs" href="/auth/login">Already have an account? Login</Link>

            <p className="register__login_link">
                <WarningTwoIcon />
                This project in still being developed, and is currently deployed for demonstration purposes.
                Users are advised to not insert real information.
            </p>
        </div>

    )
};

export default RegisterView;
