import React from 'react'
import { useState } from 'react';
import { connect } from 'react-redux';
import * as authActions from '../../../redux/auth/authActions';
import { useHistory } from 'react-router-dom';
import LoginService from '../../../services/LoginService.js';
import { useCookies } from 'react-cookie';




import './Login.css';

import {Button, Heading, Input, VStack, Box, Link} from '@chakra-ui/react';
import {ArrowForwardIcon} from '@chakra-ui/icons';
import { useToast } from "@chakra-ui/react"
import {WarningTwoIcon, InfoIcon} from "@chakra-ui/icons";

const LoginView = (props) => {
    let history = useHistory();
    const toast = useToast();
    const [cookies, setCookie] = useCookies(['auth']);

    const [loginObj, setLoginObj] = useState({
        username: "",
        password: "",
    });

    const login = async (event) => {
        event.preventDefault();
        const response = await LoginService.loginTokenRequest(loginObj);
        if (response.status === 200){
          props.saveToken(response.data.token);
          setCookie('auth', response.data.token, {path: "/"});

          history.push('/dashboard/resume');
        }
        else{
            toast({
                title: "Error logging in",
                description: "Status: " + response.status,
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        }
      };


    const demo_login = async (event) => {
        event.preventDefault();
        const response = await LoginService.loginDemoAccount();
        try{
            if (response.status === 200){
                props.saveToken(response.data.token);
                setCookie('auth', response.data.token, {path: "/"});
                history.push('/dashboard/resume');
            }
        }
        catch(err){
            toast({
                title: "Error logging in",
                description: "Status: " + err.status,
                status: "error",
                duration: 3000,
                isClosable: true,
            })
        };
    };

    return (
        <div className="login">
            <div className="login__left_container">
                <Heading s="h3" size="lg" className="login__heading"> Login </Heading>

                <form>
                    <VStack
                        spacing={4}
                        align="stretch"
                    >

                        <Box>
                            <label> Username </label>
                            <Input
                                type="text"
                                name="username"
                                value={loginObj.username}
                                onChange={(e) => setLoginObj({...loginObj, username: e.target.value})}

                            />
                        </Box>


                        <Box>
                            <label> Password </label>
                            <Input
                                type="password"
                                name="password"
                                value={loginObj.password}
                                onChange={(e) => setLoginObj({...loginObj, password: e.target.value})} />
                        </Box>

                        <Box>
                            <Button
                                colorScheme="teal"
                                isFullWidth={true}
                                onClick={login} type="submit" value="Submit"> Login </Button>
                        </Box>

                    </VStack>
                </form>

                <Link className="login__register_link" color="teal.500" size="xs" href="/auth/register">Not a member? Register Now</Link>

                <p className="login__disclaimer">
                    <WarningTwoIcon />
                    This project in still being developed, and is currently deployed for demonstration purposes.
                    Users are advised to not insert real information.
                </p>
                {/*<p className="login__disclaimer">*/}
                {/*    <InfoIcon />*/}
                {/*    */}
                {/*</p>*/}
            </div>

            <div className="login__right_container">
                <Heading size="xs">Just Looking Around? </Heading>
                <Button onClick={demo_login} className="login__demo_account_button" rightIcon={<ArrowForwardIcon />} size="sm" colorScheme="teal">Login as Demo User</Button>
                <p>
                    (Recommended)
                </p>
            </div>

        </div>
      )
}

const mapStateToProps = (state) => {
    return {
        token: state.token
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveToken: (_token) => dispatch(authActions.saveToken(_token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView)
