import axios from 'axios';
import {setCookie, getCookie} from "src/cookieUtil";
import settings from "src/localSettings";

// axios.defaults.baseURL = 'http://localhost:5000/api/v1.0/';
// axios.defaults.baseURL = 'http://184.72.83.116:5000/api/v1.0/';
axios.defaults.baseURL = 'https://jobbuddyapi.com/api/v1.0/';

export const setBearerToken = (token) => {
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};
    console.log(token);
};

axios.interceptors.request.use(
    request => {
        if (settings.reqDebugLogs) console.log(request);
        const authToken = getCookie("auth");
        setBearerToken(authToken);
        request.headers = {'Authorization': `Bearer ${authToken}`};
        request.time = { startTime: new Date() };
        return request;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        response.config.time.endTime = new Date();
        response.duration = response.config.time.endTime - response.config.time.startTime;
        if (settings.reqDebugLogs) console.log(response);
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);



export default axios;