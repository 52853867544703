import React, {useLayoutEffect, useState} from 'react'
import loadingActions from "src/redux/loading/loadingActions";
import {useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";
import {AddIcon, DownloadIcon, InfoOutlineIcon, SearchIcon} from "@chakra-ui/icons";
import {Button} from "@chakra-ui/button";
import Banner from "src/components/shared/UIShell/Banner/Banner";
import {
    Table,
    Thead,
    Tfoot,
    Th,
    Tr,
    Td,
    Tbody,
    IconButton,
    Heading,
    Divider,
    FormLabel,
    Box,
    Tooltip
} from '@chakra-ui/react';
import './ResumeView.scss';
import ResumesService from "src/services/ResumesService";
import { SizeMe } from 'react-sizeme';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const ResumeView = (props) => {
    const _dispatch = useDispatch();
    const history = useHistory();
    const [resumes, setResumes] = useState([]);
    const [selectedResume, setSelectedResume] = useState(null);
    const [selectedResumeId, setSelectedResumeId] = useState(null);


    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    useLayoutEffect(() => {
        getResumesFromAPI();
    }, []);

    const getResumesFromAPI = async () => {
        _dispatch(loadingActions.enableLoading);
        const response = await ResumesService.getResumesFromAPI();
        if (response.status === 200){
            setResumes(response.data.items);
        }
        else{
            console.log(response);
        }
        _dispatch(loadingActions.disableLoading);
    };

    const downloadResumeFromAPI = (id, showPreview=false) => async () => {
        setSelectedResumeId(id);
        const resume = resumes.find(item => item.id === id);
        const response = await ResumesService.downloadResumeFromAPI(id);
        if (response.status === 200){
            let blob = new Blob([response.data], {type: 'application/pdf'});
            const blobUrl = window.URL.createObjectURL(blob);
            if (showPreview) {
                setSelectedResume({...resume, blob: blob, blobUrl: blobUrl});
            }
            else{
                window.open(blobUrl);
            }
        }
        else{
            console.log(response);
        }
    };

    const resizeIframe = (obj) => {
        obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
    };

    const isResumeSelected = () => {
      if (selectedResume){
          // console.log(selectedResume.blob);
          return (
              <div>
                  <span className="resumes__preview_container">
                        <Heading size="sm">Selected Resume</Heading>
                        <Tooltip label="Preview of your resume." fontSize="sm">
                            <InfoOutlineIcon />
                        </Tooltip>
                    </span>
                  <Divider className="resumes__selected_project_divider"/>
                {/*<iframe id="resumeFrame" src={selectedResume.blobUrl + '#page=1&zoom=50'} />*/}
                  <div className="resumes__pdf_preview">
                  <SizeMe>
                      {({ size }) => (

                              <Document
                                  file={selectedResume.blobUrl}
                                  onLoadSuccess={onDocumentLoadSuccess}
                              >
                                  <Page width={size.width ? size.width : 1} pageNumber={pageNumber} />
                              </Document>

                      )}
                  </SizeMe>
                  </div>

              </div>
          )
      }
      else{
          return (
              <div>

              </div>
          )
      }
    };

    return (
        <div className="resumes">
            <Banner heading='Your Resumes'>
                <Button leftIcon={<AddIcon />}
                        colorScheme="teal"
                        variant="solid"
                        onClick={() => history.push('resume/new')}>
                    New Resume
                </Button>
            </Banner>

            {/* Dispaly Resume */}
            <div className="resumes__content-body">
                <div className="resumes__content-body-left">
                    <p>*Resume preview may take upto 5 seconds on first load</p>
                    <Table variant="simple" >
                        <Thead className="resumes__table_head">
                            <Tr>
                                <Th>Your Resumes</Th>
                                <Th>Description</Th>
                                <Th isNumeric>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {resumes.map((resume) => {
                                return (
                                    <Tr key={resume.id} className={resume.id === selectedResumeId ? "resumes__table_row_selected" : "resumes__table_row"} onClick={downloadResumeFromAPI(resume.id, true)}>
                                        <Td>{resume.title}</Td>
                                        <Td>{resume.description}</Td>
                                        <Td isNumeric>
                                            <IconButton style={{'margin-right': '0.2rem'}}onClick={downloadResumeFromAPI(resume.id, true)} aria-label="Preview Resume" icon={<SearchIcon />} />
                                            <IconButton onClick={downloadResumeFromAPI(resume.id)} aria-label="Download Resume" icon={<DownloadIcon />} />
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                            </Tr>
                        </Tfoot>
                    </Table>
                </div>

                <div className="resumes__content-body-right">
                    {isResumeSelected()}
                </div>
            </div>
        </div>
    )
};

export default ResumeView;
