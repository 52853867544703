import axios, { setBearerToken } from '../localaxios.js';


const LoginService = {
    loginTokenRequest: (loginObj) => {
        return axios({
            url: '/tokens', 
            method: 'POST',
            auth: {
                username: loginObj.username,
                password: loginObj.password
            },
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json'
        }})
        .then((response) => {
            console.log(response.data.token);
            setBearerToken(response.data.token);
            return response;
        })
        .catch((error) => {
            return error.response;
        });
    },

    loginDemoAccount: () => {
        return axios({
            url: '/tokens',
            method: 'POST',
            auth: {
                username: 'devadmin',
                password: 'devadmin'
            },
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }})
            .then((response) => {
                console.log(response.data.token);
                setBearerToken(response.data.token);
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    },
    logoutRequest: (loginObj) => {
        return axios({
            url: '/tokens',
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }})
            .then(function (response) {
                //  console.log(response);
                return response;

            })
            .catch(function (error) {
                return error.response;
            });
    },
};

export default LoginService;