import { SAVE_TOKEN } from './authTypes';

const initialState = {
};

const authReducer = (state = initialState, action) => {
    if (action.type === SAVE_TOKEN){
        return {
            ...state,
            token: action.payload.token
        }
    }
    return state;
};

export default authReducer;