import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import axios from 'axios';
import Register from './components/views/Register/RegisterView';
import Login from './components/views/Login/LoginView';
import DashboardLayout from './components/layouts/DashboardLayout/DashboardLayout';
import AuthLayout from "src/components/layouts/AuthLayout/AuthLayout";


function App() {
  return (
    <div className="app">

      <Router>

        <Switch>
          <Route path="/dashboard">
            <DashboardLayout />
          </Route>

          <Route path="/auth">
            <AuthLayout />
            <footer>
              <div className="wrapper">
                <small>
                  &copy;2022 <strong>WorkList</strong>,
                  Created by <a href="https://www.minhalshanjer.com">Minhal Shanjer</a>
                </small>
              </div>
            </footer>
          </Route>

        <Route path="/">
            <AuthLayout />
          <footer>
            <div className="wrapper">
              <small>
                &copy;2022 <strong>WorkList</strong>,
                Created by <a href="https://www.minhalshanjer.com">Minhal Shanjer</a>
              </small>
            </div>
          </footer>
        </Route>



        </Switch>
      </Router>
    </div>
  )

}

export default App;
