import React from 'react';
import Sidebar from "src/components/shared/UIShell/Sidebar/Siderbar";
import Navbar from "src/components/shared/UIShell/Navbar/Navbar";

import './UIShell.scss';
import Banner from "src/components/shared/UIShell/Banner/Banner";
import ContentBody from "src/components/shared/UIShell/ContentBody/ContentBody";



const UIShell = ({children}) => {
  return (
        <div className="uishell">
            <Navbar/>

            <div className="uishell__main-block">
                <Sidebar />
                <div className="uishell__content-body">
                    {children}
                </div>
            </div>

        </div>
  )
};

export default UIShell;