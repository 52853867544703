
import './Banner.scss';
import React from "react";
import {ArrowRightIcon, AddIcon} from '@chakra-ui/icons';
import {Heading, Button} from '@chakra-ui/react';

const Banner = ({heading='WorkList', Icon, children}) => {
    return (
        <div className="banner">
            <div className="banner__content">
                <span className="banner__content_left">
                    <ArrowRightIcon className="banner__icon"/>
                    <Heading>{heading}</Heading>
                </span>
                <div className="banner__content_right">
                    {children}
                </div>
            </div>
        </div>
    )
};

export default Banner;