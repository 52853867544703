import { ENABLE_LOADING, DISABLE_LOADING } from './loadingTypes';

const initialState = {
    pageLoading: false
};

const loadingReducer = (state = initialState, action) => {
    if (action.type === DISABLE_LOADING){
        return {
            ...state,
            pageLoading: false
        }
    }
    else if (action.type === ENABLE_LOADING){
        return {
            ...state,
            pageLoading: true
        }
    }
    return state;
};

export default loadingReducer;