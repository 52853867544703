import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import ExperiencesService from "src/services/ExperiencesService";
import {Button, FormControl, FormLabel, Textarea, Input, Tooltip} from '@chakra-ui/react';
import {AddIcon, ArrowBackIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import Banner from "../../shared/UIShell/Banner/Banner";

import SortableList from "src/components/shared/SortableList/SortableList";
import DynamicInput from "src/components/shared/DynamicInput/DynamicInput";
import {v4 as uuidv4} from "uuid";

import './ExperiencesView.scss';

const ExperiencesNewView = () => {
    const history = useHistory();
    const [descriptionState, setDescription] = useState([{uuid: uuidv4(), content: '', id: 1}]);

    const init_experience = {
        title: "",
        company: "",
        location: "",
        description: ""      };

    const [newExperience, setNewExperience] = useState(init_experience);

    const postExperiencesFromAPI = async (event) => {
        event.preventDefault();

        let mergedDescription = "";
        for (let i = 0; i < descriptionState.length; i++){
            mergedDescription += descriptionState[i].content + '#$%^#';
        }

        const response = await ExperiencesService.postExperiencesFromAPI( {...newExperience, description:mergedDescription });
        if (response.status === 201){
            setNewExperience(init_experience);
            history.push('/dashboard/experiences');
        }
        else{
            console.error("Could not create a new experience.");
        }
    };


    return (
        <div>
            <Banner heading='Create a new Experience'>
                <Button leftIcon={<ArrowBackIcon />}
                        variant="solid"
                        onClick={() => history.goBack()}  >   Back To Experiences
                </Button>
            </Banner>

            <div className="experiences__content-body">
                <div className="experiences__content-body-left">
                    <form onSubmit={postExperiencesFromAPI}>
                        <FormControl id="experience" >
                            <FormLabel> Title</FormLabel>
                            <Input type="text"
                                   placeholder="Enter title"
                                   value={newExperience.title}
                                   onChange={(e) => {
                                       setNewExperience({ ...newExperience, title: e.target.value });
                                   }
                                   }
                            />

                            <FormLabel> Company</FormLabel>
                            <Input type="text"
                                   placeholder="Enter Company Name"
                                   value={newExperience.company}
                                   onChange={(e) => {
                                       setNewExperience({ ...newExperience, company: e.target.value });
                                   }
                                   }
                            />

                            <FormLabel> Location</FormLabel>
                            <Input type="text"
                                   placeholder="Enter Location"
                                   value={newExperience.location}
                                   onChange={(e) => {
                                       setNewExperience({ ...newExperience, location: e.target.value });
                                   }
                                   }
                            />

                            <FormLabel> Description</FormLabel>
                            <DynamicInput parentState={descriptionState} onChange={(newDescription) => setDescription(newDescription)}/>

                            <Button type="submit" isFullWidth={true} colorScheme="teal">Create Experience</Button>
                        </FormControl>
                    </form>
                </div>
                <div className="experiences__content-body-right">
                    <span className="experiences__ordering_preview_container">
                        <FormLabel>Ordering Preview</FormLabel>
                        <Tooltip label="Drag to reorder description lines. Animations may not work yet." fontSize="sm">
                            <InfoOutlineIcon />
                        </Tooltip>
                    </span>
                    <SortableList parentState={descriptionState} onChange={(newDescription) => setDescription(newDescription)} />
                </div>
            </div>
        </div>
    )
};

export default ExperiencesNewView;