import authTypes from "src/redux/auth/authTypes";

export const saveToken = token => {
    return {
        type: authTypes.SAVE_TOKEN,
        payload: {
            token // equivalent to "token: token"
        }
    }
};


// Register all of the functions here.
const authActions = {
    saveTokeN: saveToken
};

export default authActions;