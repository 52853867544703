import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import SkillsService from "src/services/SkillsService";

import {Button, FormControl, FormLabel, Textarea, Input, Tooltip} from '@chakra-ui/react';
import {AddIcon, ArrowBackIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import Banner from "../../shared/UIShell/Banner/Banner";

import SortableList from "src/components/shared/SortableList/SortableList";
import DynamicInput from "src/components/shared/DynamicInput/DynamicInput";
import {v4 as uuidv4} from "uuid";

import './SkillsView.scss';


const SkillsNewView = () => {
    const history = useHistory();
    const [descriptionState, setDescription] = useState([{uuid: uuidv4(), content: '', id: 1}]);

    const init_skill = {name: ""};
    const [newSkill, setNewSkill] = useState(init_skill);

    const postSkillsFromAPI = async (event) => {
        event.preventDefault();
        const response = await SkillsService.postSkillsFromAPI(newSkill);
        if (response.status === 201){
            setNewSkill(init_skill);
            history.push('/dashboard/skills');
        }
        else{
            console.log(response);
        }
    };

    return (
        <div>
            <Banner heading='Create a new Skill'>
                <Button leftIcon={<ArrowBackIcon />}
                        variant="solid"
                        onClick={() => history.goBack()}  >   Back To Skills
                </Button>
            </Banner>

            <div className="skills__content-body">
                <div className="skills__content-body-left2">
                    <form onSubmit={postSkillsFromAPI}>
                        <FormControl id="skill" >

                            <FormLabel> New Skill</FormLabel>
                            <Input type="text"
                                   placeholder="Enter skill"
                                   value={newSkill.name}
                                   onChange={(e) => {
                                       setNewSkill({ ...newSkill, name: e.target.value });
                                   }
                                   }
                            />

                            {/*<FormLabel> Description</FormLabel>*/}
                            {/*<DynamicInput parentState={descriptionState} onChange={(newDescription) => setDescription(newDescription)}/>*/}

                            <Button type="submit" isFullWidth={true} colorScheme="teal" className="btn_skill">Create Skill</Button>
                        </FormControl>
                    </form>
                </div>
                {/*<div className="skills__content-body-right">*/}
                {/*    <span className="skills__ordering_preview_container">*/}
                {/*        <FormLabel>Ordering Preview</FormLabel>*/}
                {/*        <Tooltip label="Drag to reorder description lines. Animations may not work yet." fontSize="sm">*/}
                {/*            <InfoOutlineIcon />*/}
                {/*        </Tooltip>*/}
                {/*    </span>*/}
                {/*    <SortableList parentState={descriptionState} onChange={(newDescription) => setDescription(newDescription)} />*/}
                {/*</div>*/}
            </div>
        </div>
    )
};

export default SkillsNewView;