import axios from '../localaxios.js';

const ProjectsService = {
    getProjectsFromAPI: () => {
        return axios({
            url: '/projects',
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
    },

    postProjectToAPI: (newProject) => {        
        return axios({
            url: '/projects',
            method: 'POST',
            data: newProject,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
    }
}

export default ProjectsService;