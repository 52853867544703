import {ENABLE_LOADING, DISABLE_LOADING} from "src/redux/loading/loadingTypes";


export const enableLoading = {
    type: ENABLE_LOADING
};

export const disableLoading = {
    type: DISABLE_LOADING
};


const loadingActions = {
    enableLoading: enableLoading,
    disableLoading: disableLoading
};

export default loadingActions;