import React, {useState, useEffect} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux';
import { useStore } from 'react-redux'
import authActions from "src/redux/auth/authActions";
import loadingActions from "src/redux/loading/loadingActions";

import { Switch, Route, Link, useParams, useRouteMatch } from "react-router-dom";
import { dashboard_routes } from './DashboardRoutes.js';
import Header from "src/components/shared/Header";
import './DashboardLayout.css';
import Loader from "src/components/shared/Loader/Loader";
import LoaderHOC from "src/components/shared/LoaderHOC/LoaderHOC";
// import {setBearerToken} from "src/localaxios";
import { useCookies } from 'react-cookie';
import UIShell from "src/components/shared/UIShell/UIShell";
import ContentBody from "src/components/shared/UIShell/ContentBody/ContentBody";

const DashboardLayout = (props) => {
    const LoaderContext = React.createContext(false);
    const [loader, setLoader] = useState(false);
    let { path, url } = useRouteMatch();
    const [cookies, setCookie] = useCookies();

    // const loadingState = useSelector(state => state.loadingReducer.pageLoading);
    // const dispatch = useDispatch();

    useEffect(() => {
        console.log("#debug");
        console.log(cookies);
        console.log(cookies.auth);
    }, []);

    const toggleLoading = (flag = false) => {
      if (flag === true) setLoader(true);
      else setLoader(false);
    };

    const getRoutes = () => {
        return dashboard_routes.map((item) => {
            // if (loader === true){
            //     return (
            //         <Loader />
            //     )
            // }
            return (
                <Route path={`${path}` + item.path}>
                    <LoaderHOC show={props.loadingState}>
                        <ContentBody customBanner={item.customBannerImplementation}>
                            {item.component}
                        </ContentBody>
                    </LoaderHOC>
                    {/*{loader ? <Loader/> : item.component}*/}
                </Route>
            )
        });
    };

    const toggleLoader = (value=undefined) => {
        if (value === undefined){
            setLoader(!loader);
        }
        else if (value === true){
            setLoader(true);
        }
        else{
            setLoader(false);
        }
    };

    return (
        <div className="dashboard">
            <UIShell>
                <LoaderContext.Provider value="false">
                    <Switch>
                        {getRoutes()}
                    </Switch>
                </LoaderContext.Provider>
            </UIShell>
            {/*<Sidebar />*/}
            {/*<div className="dashboard__body">*/}
            {/*    <Header />*/}
            {/*    <div className="dashboard__content">*/}
            {/*        <LoaderContext.Provider value="false">*/}
            {/*            <Switch>*/}
            {/*                {getRoutes()}*/}
            {/*            </Switch>*/}
            {/*        </LoaderContext.Provider>*/}
            {/*    </div>*/}

            {/*</div>*/}

            {/* Footer*/}
            {/* <Footer /> */}
        </div>
    )
};
//
// const mapStateToProps = (state) => ({
//     loadingState: state.loadingReducer.pageLoading
// });

// const mapStateToProps = (state) => {
//     return {
//         token: state.token
//     }
// };
//
// const mapDispatchToProps = dispatch => {
//     return {
//         saveToken: (_token) => dispatch(authActions.saveToken(_token)),
//         enableLoading: () => dispatch(loadingActions.enableLoading()),
//         disableLoading: () => dispatch(loadingActions.disableLoading())
//     }
// };

// export default connect(mapStateToProps, null)(DashboardLayout);

export default DashboardLayout;

