import './Navbar.scss';
import {Heading, Button} from "@chakra-ui/react";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuIcon,
    MenuCommand,
    MenuDivider,
} from "@chakra-ui/react";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";
import React from "react";
import axios from "axios";
import {useHistory} from 'react-router-dom';
import { Link } from "@chakra-ui/react"
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Link as ReachLink } from "react-router-dom";
import LoginService from "src/services/LoginService";


const Navbar = () => {
    const history = useHistory();
    const logout = async (event) => {
        event.preventDefault();
        try {
            await LoginService.logoutRequest();
        } catch (error) {
            console.log(error);
        }
        history.push('/login');
    };

    return (
        <div className="navbar">
            <Heading className="navbar__logo">WorkList </Heading>
            <div className="navbar__right">
                <Menu>
                    <MenuButton className="navbar__menu">
                        <span className="navbar__menu_container">
                            <Avatar size="sm" name="Minhal Shanjer" className="navbar__avatar">
                            </Avatar>
                            Minhal Shanjer
                            <ChevronDownIcon/>
                        </span>

                    </MenuButton>
                    <MenuList className="navbar__dropdown">
                        <Link as={ReachLink} to="/dashboard/profile">

                            <MenuItem  >
                                Profile
                            </MenuItem  >
                        </Link>

                        {/*<MenuItem  >  Profile  </MenuItem  >*/}



                        <MenuItem   onClick={logout}> Logout   </MenuItem>
                    </MenuList>
                </Menu>
            </div>
        </div>
    )
};

export default Navbar;