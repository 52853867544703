import React from 'react';
import { useHistory } from "react-router-dom";
import {Button} from '@chakra-ui/react';
import {ArrowBackIcon} from "@chakra-ui/icons";
import Banner from "src/components/shared/UIShell/Banner/Banner";

const CoverLetterNewView = () => {
    const history = useHistory();

    return (
        <div>
            <Banner heading='Create new Cover Letter'>
                <Button leftIcon={<ArrowBackIcon />}
                        variant="solid"
                        onClick={() => history.goBack()}
                >
                    Back To Cover Letter
                </Button>
            </Banner>
        </div>
    )
};

export default CoverLetterNewView;