import React, {useLayoutEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Table,
    Tbody,
    Td,
    Textarea,
    Tfoot,
    Th,
    Thead,
    Tr,
    Checkbox,
    CheckboxGroup, Tooltip, Heading, Divider, Box, Image, Radio, RadioGroup
} from '@chakra-ui/react';
import {ArrowBackIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import Banner from "src/components/shared/UIShell/Banner/Banner";
import DynamicInput from "src/components/shared/DynamicInput/DynamicInput";
import './ResumeView.scss';
import loadingActions from "src/redux/loading/loadingActions";
import ProjectsService from "src/services/ProjectsService";
import ExperiencesService from "src/services/ExperiencesService";
import {useDispatch} from "react-redux";
import SkillsService from "src/services/SkillsService";
import ResumesService from "src/services/ResumesService";

const ResumeNewView = () => {
    const history = useHistory();
    const _dispatch = useDispatch();

    const initResume = {
        title: "",
        description: "",
        projects: [],
        experiences: []
    };
    const [newResume, setNewResume] = useState(initResume);
    const [projects, setProjects] = useState([]);
    const [experiences, setExperiences] = useState([]);
    const [template, setTemplate] = React.useState("basic");



    useLayoutEffect(() => {
        getExperiencesFromAPI();
        getProjectsFromAPI();
    }, []);

    const getProjectsFromAPI = async () => {
        _dispatch(loadingActions.enableLoading);
        const response = await ProjectsService.getProjectsFromAPI();
        if (response.status === 200){
            setProjects(response.data.items);
        }
        else{
            console.log(response);
        }
        _dispatch(loadingActions.disableLoading);
    };

    const getExperiencesFromAPI = async () => {
        _dispatch(loadingActions.enableLoading);
        const response = await ExperiencesService.getExperiencesFromAPI();
        if (response.status === 200){
            setExperiences(response.data.items);
        }
        else{
            console.error("Could not retrieve experiences");
        }
        _dispatch(loadingActions.disableLoading);
    };

    const onExperienceSelect = (id) => (event) => {
        if (event.target.checked){
            setNewResume({...newResume, experiences: [...newResume.experiences, id]});
        }
        else{
            const filteredExperiences = newResume.experiences.filter(item => item !== id);
            setNewResume(
                {
                    ...newResume,
                    experiences: filteredExperiences
                });
        }
    };

    const onProjectSelect = (id) => (event) => {
        if (event.target.checked){
            setNewResume({...newResume, projects: [...newResume.projects, id]});
        }
        else{
            const filteredProjects = newResume.projects.filter(item => item !== id);
            console.log(filteredProjects);
            setNewResume(
                {
                    ...newResume,
                    projects: filteredProjects
                });
        }
    };

    const postResumeToAPI = async (event) => {
        event.preventDefault();
        // console.log(newResume);
        // console.log(template);
        const data = {...newResume, template: template};
        console.log(data);
        const response = await ResumesService.postResumeToAPI(data);
        if (response.status === 201){
            setNewResume(initResume);
            history.push('/dashboard/resume');
        }
        else{
            console.log(response);
        }
    };


    return (
        <div className="resumes">
            <Banner heading='Create New Resume'>
                <Button leftIcon={<ArrowBackIcon />}
                        variant="solid"
                        onClick={() => history.goBack()}
                >
                    Back To Resumes
                </Button>
            </Banner>

            <div className="resumes__content-body">
                <div className="resumes__content-body-left">
                    <form>
                        <FormControl id="resume" >
                            <FormLabel> Resume Style </FormLabel>
                            <RadioGroup className="resumes__template_row" onChange={setTemplate} value={template}>
                                <Box p={0.5} className="resumes__template_item" bg="#f4f4f4" maxW="13vw" borderWidth="1px" borderRadius="md">
                                    <Image src="/resumeTemplateImages/basic.png" alt="Basic Resume Template" />
                                    <Radio colorScheme="green" className="resumes__template_item_radio" value="basic" defaultIsChecked>
                                        <Heading fontSize="1rem" size="sm" className="resumes__template_item_label">Basic Template</Heading>
                                    </Radio>

                                </Box>
                                <Box p={0.5} className="resumes__template_item" bg="#f4f4f4" maxW="13vw" borderWidth="1px" borderRadius="md">
                                    <Image src="/resumeTemplateImages/column.png" alt="Column Resume Template" />
                                    <Radio colorScheme="green" className="resumes__template_item_radio" value="column">
                                        <Heading fontSize="1rem" size="sm" className="resumes__template_item_label">Column Template</Heading>
                                    </Radio>
                                </Box>
                            </RadioGroup>


                            <FormLabel>Resume Title</FormLabel>
                            <Input type="text"
                                   placeholder="Resume title"
                                   value={newResume.title}
                                   onChange={(e) => {
                                       setNewResume({ ...newResume, title: e.target.value });
                                   }
                                   }
                            />

                            <FormLabel>Resume Description</FormLabel>
                            <Textarea onChange={(e) => {
                                setNewResume({ ...newResume, description: e.target.value });
                            }}
                            />

                            <Table variant="simple" size="sm" className="resumes_new__table" >
                                <Thead className="resumes__table_head">
                                    <Tr>
                                        <Th>Your Experiences</Th>
                                        <Th>Company</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {experiences.map(experience => {
                                        return (
                                            <Tr>
                                                <Td>
                                                    <Checkbox colorScheme="teal" onChange={onExperienceSelect(experience.id)}>
                                                        {experience.title}
                                                    </Checkbox>
                                                </Td>
                                                <Td>{experience.company}</Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                                <Tfoot>
                                    <Tr>
                                    </Tr>
                                </Tfoot>
                            </Table>

                            <Table variant="simple" size="sm">
                                <Thead className="resumes__table_head">
                                    <Tr className="resumes_new__table_header_row">
                                        <Th className="resumes_new__table_title">Your Projects</Th>
                                        <Th className="resumes_new__table_description">Description</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {projects.map(project => {
                                        return (
                                            <Tr className="resumes_new__table_row">
                                                <Td className="resumes_new__table_title">
                                                    <Checkbox colorScheme="teal" onChange={onProjectSelect(project.id)}>
                                                        {project.title}
                                                    </Checkbox>
                                                </Td>
                                                <Td className="resumes_new__table_description">{project.description[0].substr(0, 200)}...</Td>
                                            </Tr>
                                        )
                                    })}

                                </Tbody>
                                <Tfoot>
                                    <Tr>
                                    </Tr>
                                </Tfoot>
                            </Table>



                            <Button type="submit" onClick={postResumeToAPI} isFullWidth={true} colorScheme="teal">Create Resume</Button>
                        </FormControl>


                    </form>
                </div>

                <div className="resumes__content-body-right">
                    <span className="resumes__preview_container">
                        <Heading size="sm">Selected Project</Heading>
                        <Tooltip label="Contents of your resume. Click the Preview button to see generated resume." fontSize="sm">
                            <InfoOutlineIcon />
                        </Tooltip>
                    </span>
                    <Divider className="resumes__selected_project_divider"/>
                </div>
            </div>

        </div>
    )
};

export default ResumeNewView;