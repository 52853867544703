import React, {useEffect, useLayoutEffect, useState} from 'react'
import loadingActions from "src/redux/loading/loadingActions";
import {useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";
import {EditIcon} from "@chakra-ui/icons";
import {Button} from "@chakra-ui/button";
import Banner from "src/components/shared/UIShell/Banner/Banner";
import {Table, Thead, Tfoot, Th, Tr, Td,  Tbody, Heading,  Divider, FormLabel, Box} from '@chakra-ui/react';
import './ProfileView.scss';
import ProfileService from "src/services/ProfileService";
import axios from "axios";
import ProjectsService from "src/services/ProjectsService";

const ProfileView = (props) => {
    const _dispatch = useDispatch();
    const history = useHistory();

    const [profileState, setProfileState] = useState({
        username: "",
        email: "",
        phone: "",
        linkedin: "",
        website: "",
        github: ""
    });

    const [editBoolean, setEditBoolean] = useState(false);

    useLayoutEffect(() => {
        getProfileFromAPI();
    }, []);

    const getProfileFromAPI = async () => {
        _dispatch(loadingActions.enableLoading);
        const response = await ProfileService.getProfileFromAPI();
        if (response.status === 200){
            setProfileState(response.data.contact);
        }
        else{
            console.log(response);
        }
        _dispatch(loadingActions.disableLoading);
    };

    const postProfileToAPI = async (event) => {
        event.preventDefault();

        const response = await ProfileService.putProfileToAPI(profileState);
        console.log('Response  ' + response);
        if (response.status === 201){
            setEditBoolean(!editBoolean);
            getProfileFromAPI();
        }
        else{
            console.log(response);
        }
    };

    return (
        <div className="profile">
            <Banner heading='Your Profile'>
                <Button leftIcon={<EditIcon />}
                        colorScheme="teal"
                        variant="solid"
                        onClick={() => history.push('profile/edit')}>
                    Edit Profile
                </Button>
            </Banner>

            {/* Dispaly Resume */}
            <div className="profile__content-body">
                <div className="profile__content-body-left">
                    <Table variant="simple" >
                        <Thead className="profile__table_head">
                            <Tr>
                                <Th>Your Profile</Th>
                            </Tr>
                        </Thead>

                    </Table>

                    <div>
                        {/* Dispaly list of projects */}
                        <h3>User Profile</h3>
                        <button onClick={(e) => setEditBoolean(!editBoolean)}>
                            {!editBoolean ? 'Edit Profile' : 'Finish Editting' }
                        </button>

                        <br />
                        <br />

                        {/* Create input form*/}

                        <form>

                            {/* Name */}
                            <input
                                placeholder="Name"
                                value={profileState.name}
                                onChange={(e) => {
                                    setProfileState({...profileState, name:e.target.value});
                                }
                                }
                                disabled={!editBoolean}
                            />

                            <br />

                            {/* Email Address */}
                            <input
                                placeholder="Email Address"
                                value={profileState.email}
                                onChange={(e) => {
                                    setProfileState({...profileState, email:e.target.value});
                                }
                                }
                                disabled={!editBoolean}
                            />
                            <br />

                            {/* Phone */}
                            <input
                                placeholder="Phone"
                                value={profileState.phone}
                                onChange={(e) => {
                                    setProfileState({...profileState, phone:e.target.value});
                                }
                                }
                                disabled={!editBoolean}
                            />
                            <br />

                            {/* LinkedIn */}
                            <input
                                placeholder="LinkedIn"
                                value={profileState.linkedin}
                                onChange={(e) => {
                                    setProfileState({...profileState, linkedin:e.target.value});
                                }
                                }
                                disabled={!editBoolean}
                            />
                            <br />

                            {/* Personal Website */}
                            <input
                                placeholder="Personal Website"
                                value={profileState.website}
                                onChange={(e) => {
                                    setProfileState({...profileState, website:e.target.value});
                                }
                                }
                                disabled={!editBoolean}
                            />
                            <br />

                            {/* Github */}
                            <input
                                placeholder="Github Link"
                                value={profileState.github}
                                onChange={(e) => {
                                    setProfileState({...profileState, github:e.target.value});
                                }
                                }
                                disabled={!editBoolean}
                            />
                            <br />


                            <button type="submit" onClick={postProfileToAPI}>Update Profile</button>
                        </form>


                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProfileView;
