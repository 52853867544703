import React from 'react'
import './LoaderHOC.scss';
import {useSelector} from "react-redux";


const LoaderHOC = ({show, children}) => {
    const loadingState = useSelector(state => state.loadingReducer.pageLoading);


    return (
        <>
            {loadingState &&
            <div className="loader_background">
                <div className="loader" />
            </div>
            }
            {children}
        </>
    )
};

export default LoaderHOC;