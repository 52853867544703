import React, {useState} from 'react';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
    restrictToVerticalAxis,
    restrictToWindowEdges,
} from '@dnd-kit/modifiers';

import SortableItem from "src/components/shared/SortableList/SortableItem";


const SortableList = (props) => {
    const [items, setItems] = useState(['1', '2', '3']);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={props.parentState}
                strategy={verticalListSortingStrategy}
            >
                {props.parentState.map((item, index) => <SortableItem key={item.id} id={item.id} content={item.content} />)}
            </SortableContext>
        </DndContext>
    );

    function handleDragEnd(event) {
        const {active, over} = event;
        console.log(event);

        if (active.id !== over.id) {
            props.onChange((items) => {
                console.log(items);

                const oldIndex = items.findIndex(x => x.id === active.id);
                const newIndex = items.findIndex(x => x.id === over.id);
                console.log(oldIndex, newIndex);

                return arrayMove(items, oldIndex, newIndex);
            });
        }
    }
};

export default SortableList;