import axios from '../localaxios.js';

const SkillsService = {
    getSkillsFromAPI: () => {
        return axios({
            url: '/skills',
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }})
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
    },

    postSkillsFromAPI: (newSkill) => {
        return axios({
            url: '/skills', 
            method: 'POST',
            data: newSkill,
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
            }})
          .then(function (response) {
            return response;
          })
          .catch(function (error) {
            return error.response;
          });
    }

}

export default SkillsService;