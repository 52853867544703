import SkillsView from 'src/components/views/Skills/SkillsView.jsx';
import ProjectsNewView from "src/components/views/Projects/ProjectsNewView";
import ProjectsView from "src/components/views/Projects/ProjectsView";
import QualificationsView from 'src/components/views/Qualifications/QualificationsView';
import ExperiencesView from "src/components/views/Experiences/ExperiencesView";
import DashboardHomeView from "src/components/views/DashboardHome/DashboardHomeView";
import Loader from "src/components/shared/Loader/Loader";
import ExperiencesNewView from "src/components/views/Experiences/ExperiencesNewView";
import SkillsNewView from "src/components/views/Skills/SkillsNewView";
import QualificationsNewView from "src/components/views/Qualifications/QualificationsNewView";
import ResumeNewView from "src/components/views/Resumes/ResumeNewView";
import ResumeView from "src/components/views/Resumes/ResumeView";
import CoverLetterView from "src/components/views/CoverLetter/CoverLetterView";
import CoverLetterNewView from "src/components/views/CoverLetter/CoverLetterNewView";
import ProfileView from "src/components/views/Profile/ProfileView";

import React from "react";


export const dashboard_routes = [
    {
        path: "/projects/new",
        name: "Projects New Item",
        // icon: "ni ni-paper-diploma text-black",
        component: <ProjectsNewView />,
        customBannerImplementation: true,
        layout: "/dashboard"
    },
    {
        path: "/projects",
        name: "Projects",
        // icon: "ni ni-paper-diploma text-black",
        component: <ProjectsView />,
        passProps: props => <ProjectsView {...props} />,
        customBannerImplementation: true,
        layout: "/dashboard"
    },

    {
        path: "/experiences/new",
        name: "Experiences New Item",
        // icon: "ni ni-paper-diploma text-black",
        component: <ExperiencesNewView />,
        customBannerImplementation: true,
        layout: "/dashboard",

    },
    {
        path: "/experiences",
        name: "Experiences",
        // icon: "ni ni-paper-diploma text-black",
        component: <ExperiencesView />,
        passProps: props => <ExperiencesView {...props} />,
        customBannerImplementation: true,
        layout: "/dashboard"
    },

    {
        path: "/skills/new",
        name: "Skills New Item",
        // icon: "ni ni-paper-diploma text-black",
        component: <SkillsNewView />,
        customBannerImplementation: true,
        layout: "/dashboard"
    },
    {
        path: "/skills",
        name: "Skills",
        // icon: "ni ni-paper-diploma text-black",
        component: <SkillsView />,
        passProps: props => <SkillsView {...props} />,
        customBannerImplementation: true,
        layout: "/dashboard"
    },

    {
        path: "/qualifications/new",
        name: "Qualifications New Item",
        // icon: "ni ni-paper-diploma text-black",
        component: <QualificationsNewView />,
        customBannerImplementation: true,
        layout: "/dashboard"
    },
    {
        path: "/qualifications",
        name: "Qualifications",
        // icon: "ni ni-paper-diploma text-black",
        component: <QualificationsView />,
        passProps: props => <QualificationsView {...props} />,
        customBannerImplementation: true,
        layout: "/dashboard"
    },

    {
        path: "/resume/new",
        name: "Resume New Item",
        // icon: "ni ni-paper-diploma text-black",
        component: <ResumeNewView />,
        customBannerImplementation: true,
        layout: "/dashboard"
    },
    {
        path: "/resume",
        name: "Resumes",
        // icon: "ni ni-paper-diploma text-black",
        component: <ResumeView />,
        passProps: props => <ResumeView {...props} />,
        customBannerImplementation: true,
        layout: "/dashboard"
    },

    {
        path: "/cover_letter/new",
        name: "Cover Letter New Item",
        // icon: "ni ni-paper-diploma text-black",
        component: <CoverLetterNewView />,
        customBannerImplementation: true,
        layout: "/dashboard"
    },
    {
        path: "/cover_letter",
        name: "CoverLetter",
        // icon: "ni ni-paper-diploma text-black",
        component: <CoverLetterView />,
        passProps: props => <CoverLetterView {...props} />,
        customBannerImplementation: true,
        layout: "/dashboard"
    },
    {
        path: "/profile",
        name: "Profile",
        // icon: "ni ni-paper-diploma text-black",
        component: <ProfileView />,
        passProps: props => <ProfileView {...props} />,
        customBannerImplementation: true,
        layout: "/dashboard"
    },

    { // important that this is last from all
        path: "/",
        name: "Worklist",
        // icon: "ni ni-paper-diploma text-black",
        component: <DashboardHomeView />,
        passProps: props => <DashboardHomeView {...props} />,
        customBannerImplementation: false,
        layout: "/dashboard"
    }
];

