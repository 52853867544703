import axios, { setBearerToken } from '../localaxios';

const RegisterService = {
    signUp: (signUpObj) => {
        //inspect the value
        return axios({
            url: '/users',
            method: 'POST',
            data: signUpObj,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        });
    },

    validateRegisterForm: function (regObj) {
        let username_check = false;
        let password_check = false;

        if (regObj.username.length > 6) {
            username_check = true;
        }
        else {
            username_check = false;
        }

        if (regObj.password == regObj.confirm_password) password_check = true;
        else password_check = false;

        const status = password_check && username_check;
        return status;
    }
};

export default RegisterService;