import React, {useState, useEffect, useLayoutEffect} from 'react'
import ProjectsService from "src/services/ProjectsService";
import loadingActions from "src/redux/loading/loadingActions";
import {useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";
import {AddIcon} from "@chakra-ui/icons";
import {Button} from "@chakra-ui/button";
import Banner from "src/components/shared/UIShell/Banner/Banner";
import {Table, Thead, Tfoot, Th, Tr, Td, TableCaption, Tbody, Heading, VStack, Divider, FormLabel, Box} from '@chakra-ui/react';
import './ProjectsView.scss';

const ProjectsView = (props) => {
    const _dispatch = useDispatch();
    const history = useHistory();

    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);

    useLayoutEffect(() => {
        getProjectsFromAPI();
    }, []);

    const getProjectsFromAPI = async () => {
        _dispatch(loadingActions.enableLoading);
        const response = await ProjectsService.getProjectsFromAPI();
        if (response.status === 200){
            setProjects(response.data.items);
        }
        else{
            console.log(response);
        }
        _dispatch(loadingActions.disableLoading);
    };

    const onSelectProject = (id) => (event) => {
        event.preventDefault();
        // alert(id);
        const proj = projects.find(project => project.id === id);
        // console.log(proj);
        setSelectedProject(proj);
        console.log(selectedProject);
    };

    const isProjectSelected = () => {
        if (selectedProject){
            return (
                <div>
                    <Heading size="sm">Selected Project</Heading>
                    <Divider className="project__selected_project_divider"/>
                    <FormLabel>Project Title</FormLabel>
                    <Box d="flex" alignItems="center" bg="gray.100" w="100%" p={4} borderRadius="lg" fontsiz="sm"
                         style={{"marginBottom": "0.2rem", "display": "flex", "justifyContent": "space-between"}}>
                       {selectedProject.title}
                    </Box>

                    <FormLabel>Project ID</FormLabel>
                    <Box d="flex" alignItems="center" bg="gray.100" w="100%" p={4} borderRadius="lg" fontsiz="sm"
                         style={{"marginBottom": "0.2rem", "display": "flex", "justifyContent": "space-between"}}>
                        {selectedProject.id}
                    </Box>

                    <FormLabel>Project Description</FormLabel>
                    {selectedProject.description.map( (descriptionLine, index) => {
                        if (descriptionLine !== ""){ // don't render empty description lines (should ideally be none in the first place)
                            return (
                                <Box key={index} d="flex" alignItems="center" bg="gray.100" w="100%" p={4} borderRadius="lg" fontsiz="sm"
                                     style={{"marginBottom": "0.2rem", "display": "flex", "justifyContent": "space-between"}}>
                                    {descriptionLine}
                                </Box>
                            )
                        }
                    })}

                </div>
            );
        }
    };

    return (
        <div className="projects">
            <Banner heading='Projects'>
                <Button leftIcon={<AddIcon />}
                        colorScheme="teal"
                        variant="solid"
                        onClick={() => history.push('projects/new')}>
                    New Project
                </Button>
            </Banner>

            {/* Dispaly list of projects */}
            <div className="projects__content-body">
                <div className="projects__content-body-left">
                    <Table variant="simple" >
                        <Thead className="projects__table_head">
                            <Tr>
                                <Th>Your Projects</Th>
                                <Th>Description</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {projects.map(project => {
                                return (
                                    <Tr className={project.id === selectedProject?.id ? "projects__table_row_selected" : "projects__table_row"}
                                        key={project.id} onClick={onSelectProject(project.id)}>
                                        <Td>{project.title}</Td>
                                        <Td>{project.description[0].substr(0, 50)}...</Td>
                                        <Td>{project.date}</Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Th>Your Projects</Th>
                                <Th>Description</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </div>

                <div className="projects__content-body-right">
                    {isProjectSelected()}
                </div>

            </div>
        </div>
    )
        

};

export default ProjectsView;
