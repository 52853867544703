import React from 'react'

const DashboardHomeView = () => {
    return (
        <div>
            <h2>Home Page</h2>
        </div>
    )
};

export default DashboardHomeView;
