import React from 'react';
import Banner from "src/components/shared/UIShell/Banner/Banner";


// if Banner is custom implemented, then there is no styling
// and children is responsible for styling itself
const shouldRenderBanner = (customBanner, children) => {
    if (!customBanner) {
        return (
            <>
                <Banner />
                <div className="uishell__child-content-body">
                    {children}
                </div>
            </>
        )
    }
    return (
        <>
        {children}
        </>
    )
};

const ContentBody = ({customBanner=false, children}) => {
    return shouldRenderBanner(customBanner, children);
};

export default ContentBody;