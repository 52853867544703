import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Box} from '@chakra-ui/react';
import {ArrowUpDownIcon} from "@chakra-ui/icons";

function SortableItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: props.id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <Box d="flex" alignItems="center" bg="gray.100" w="100%" p={4} borderRadius="lg" fontsiz="sm"
                 style={{"marginBottom": "0.2rem", "display": "flex", "justifyContent": "space-between"}}>
                {props.content}
                <ArrowUpDownIcon w={4} h={4} color="grey.500" />
            </Box>
        </div>
    );
}

export default SortableItem;