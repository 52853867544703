import React, {useEffect, useLayoutEffect, useState} from 'react';
import SkillsService from 'src/services/SkillsService.js';
import {connect, useDispatch} from 'react-redux';
import loadingActions from "src/redux/loading/loadingActions";
import {useHistory} from "react-router-dom";

import {Box, Button, Divider, FormLabel, Heading} from "@chakra-ui/react";
import {AddIcon} from "@chakra-ui/icons";
import Banner from "../../shared/UIShell/Banner/Banner";
import {Table, Thead, Tfoot, Th, Tr, Td, TableCaption, Tbody} from '@chakra-ui/react';
import './SkillsView.scss'

import * as authActions from 'src/redux/auth/authActions.js';
// import axios from '../../axios';

const SkillsView = (props) => {
    const _dispatch = useDispatch();
    const history = useHistory();

    const [skills, setSkills] = useState([]);
    const [selectedSkill, setSelectedSkill] = useState(null);


    useLayoutEffect(() => {
        getSkillsFromAPI();
    }, []);

    const getSkillsFromAPI = async () => {
        _dispatch(loadingActions.enableLoading);
        const response = await SkillsService.getSkillsFromAPI();
        if (response.status === 200){
            console.log(response);
            setSkills(response.data);
        }
        else{
            console.error("Could not retrieve qualifications");
        }
        _dispatch(loadingActions.disableLoading);
    };


    const onSelectSkill = (id) => (event) => {
        event.preventDefault();
        // alert(id);
        const skl = skills.find(skill => skill.id === id);
        // console.log(proj);
        setSelectedSkill(skl);
        console.log(selectedSkill);
    };

    const isSkillSelected = () => {
        if (selectedSkill){
            return (
                <div>
                    <Heading size="sm">List of Projects and Experiences Related To Skill</Heading>
                    <Divider className="skills__selected_skill_divider"/>
                    <FormLabel>Skill Title</FormLabel>
                    <Box d="flex" alignItems="center" bg="gray.100" w="100%" p={4} borderRadius="lg" fontsiz="sm"
                         style={{"marginBottom": "0.2rem", "display": "flex", "justifyContent": "space-between"}}>
                        {selectedSkill.name}
                    </Box>
                </div>
            );
        }
    };


    return (
        <div>
            <Banner heading='Skills'>
                <Button leftIcon={<AddIcon />}
                        colorScheme="teal"
                        variant="solid"
                        onClick={() => history.push('skills/new')}>
                    New Skills
                </Button>
            </Banner>

            {/* Dispaly list of Skills */}

            <div className="skills__content-body">
                <div className="skills__content-body-left">
                    <Table variant="simple" >
                        <Thead className="skills__table_head">
                            <Tr>
                                <Th>Skills</Th>

                            </Tr>
                        </Thead>
                        <Tbody>
                            {skills.map(skill => {
                                return (
                                    <Tr className={skill.id === selectedSkill?.id ? "skills__table_row_selected" : "skills__table_row"} key={skill.id} onClick={onSelectSkill(skill.id)}>
                                        <Td> {skill.name} </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Th>Skills</Th>
                            </Tr>
                        </Tfoot>
                    </Table>


                </div>

                <div className="skills__content-body-right">
                    {isSkillSelected()}
                </div>
            </div>

        </div>
    )
};

export default SkillsView;

