import React, {useState, useEffect} from 'react'

import { Switch, Route, Link, useParams, useRouteMatch } from "react-router-dom";
import { auth_routes } from './AuthRoutes.js';
import './AuthLayout.css';
import LoaderHOC from "src/components/shared/LoaderHOC/LoaderHOC";
import {Heading, Button, Image, Box} from "@chakra-ui/react"



const AuthLayout = (props) => {
    const LoaderContext = React.createContext(false);
    const [loader, setLoader] = useState(false);
    let { path, url } = useRouteMatch();

    const toggleLoading = (flag = false) => {
        if (flag === true) setLoader(true);
        else setLoader(false);
    };

    const getRoutes = () => {
        return auth_routes.map((item) => {
            return (
                <Route path={`${path}` + item.path}>
                    <LoaderHOC show={props.loadingState}>
                        {item.component}
                    </LoaderHOC>
                </Route>
            )
        });
    };

    const toggleLoader = (value=undefined) => {
        if (value === undefined){
            setLoader(!loader);
        }
        else if (value === true){
            setLoader(true);
        }
        else{
            setLoader(false);
        }
    };

    return (
        <div className="auth_layout">
            <div className="auth_navbar">
                <Heading className="auth_navbar__logo">WorkList </Heading>

                <div className="left__nav">
                    <a href="https://www.minhalshanjer.com">
                        <Button colorScheme="teal">
                            About Me
                        </Button>
                    </a>
                </div>
            </div>

            <div className="auth_body">
                <div className="auth_description">
                    <div className="auth_resume_text">
                        <p className="auth_resume_paragraph">
                            WorkList manages your extended resume details to curate the best possible resume
                            for any given job description with the help of NLP.
                        </p>
                        <p className="auth_resume_paragraph">
                            Choose different templates to change up your resume on the go.
                        </p>
                        <p className="auth_resume_paragraph">
                            Android app coming soon on Play Store! Stay tuned.
                        </p>
                    </div>
                    <Image className="auth_resume_image" src="/resumeTemplateImages/resumepng.png" alt="Resume Image" />
                </div>

                {/* maybe add navigation bar here*/}
                <div className="auth_content">
                    <div className='auth_card'>
                        <LoaderContext.Provider value="false">
                            <Switch>
                                {getRoutes()}
                            </Switch>
                        </LoaderContext.Provider>
                    </div>
                </div>

            </div>


        </div>
    )
};

export default AuthLayout;

