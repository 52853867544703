import React from 'react'
import loadingActions from "src/redux/loading/loadingActions";
import {useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";
import {AddIcon} from "@chakra-ui/icons";
import {Button} from "@chakra-ui/button";
import Banner from "src/components/shared/UIShell/Banner/Banner";
import {Table, Thead, Tfoot, Th, Tr, Td,  Tbody, Heading,  Divider, FormLabel, Box} from '@chakra-ui/react';
import './CoverLetterView.scss';

const CoverLetterView = (props) => {
    const _dispatch = useDispatch();
    const history = useHistory();

    return (
        <div className="coverletters">
            <Banner heading='Cover Letter'>
                <Button leftIcon={<AddIcon />}
                        colorScheme="teal"
                        variant="solid"
                        onClick={() => history.push('cover_letter/new')}>
                    New Cover Letter
                </Button>
            </Banner>

            {/* Dispaly Cover Letter */}
            <div className="cover_letters__content-body">
                <div className="cover_letters__content-body-left">
                    <Table variant="simple" >
                        <Thead className="cover_letters__table_head">
                            <Tr>
                                <Th>Your Cover Letter</Th>
                            </Tr>
                        </Thead>
                        <Tbody>

                        </Tbody>

                    </Table>
                </div>
            </div>
        </div>
    )
};

export default CoverLetterView;
