import axios from '../localaxios.js';

const ResumesService = {
    getResumesFromAPI: () => {
        return axios({
            url: '/resumes',
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
    },

    downloadResumeFromAPI: (id) => {
        return axios({
            url: `/resumes/${ id }/download`,
            method: 'GET',
            responseType: 'blob', // Important
            headers: {
                'Access-Control-Max-Age': '2',
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
    },

    postResumeToAPI: (newResume) => {
        return axios({
            url: '/resumes',
            method: 'POST',
            data: newResume,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
    }
};

export default ResumesService;