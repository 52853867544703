import {combineReducers, createStore} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './auth/authReducer';
import loadingReducer from "src/redux/loading/loadingReducer";

const persistConfig = {
    key: 'root',
    storage,
};

const authPersistReducer = persistReducer(persistConfig, authReducer);

const rootReducer = combineReducers({
    authPersistReducer,
    loadingReducer
});


export let store = createStore(rootReducer);
export let persistor = persistStore(store);
