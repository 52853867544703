import axios from '../localaxios.js';

const ProfileService = {
    getProfileFromAPI: () => {
        return axios({
            url: '/me',
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
    },

    putProfileToAPI: (profiledata) => {
        return axios({
            url: '/users',
            method: 'PUT',
            data: profiledata,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response;
            });
    }
};

export default ProfileService;