import React, {useState, useEffect, useLayoutEffect} from 'react';
import ExperiencesService from "src/services/ExperiencesService";
import loadingActions from "src/redux/loading/loadingActions";
import {useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";
import {Box, Button, Divider, FormLabel, Heading} from "@chakra-ui/react";
import {AddIcon} from "@chakra-ui/icons";
import Banner from "../../shared/UIShell/Banner/Banner";
import {Table, Thead, Tfoot, Th, Tr, Td, TableCaption, Tbody} from '@chakra-ui/react';
import './ExperiencesView.scss'

const ExperiencesView = (props) => {
    const _dispatch = useDispatch();
    const history = useHistory();

    const [experiences, setExperiences] = useState([]);
    const [selectedExperience, setSelectedExperience] = useState(null);


    useLayoutEffect(() => {
        getExperiencesFromAPI();
    }, []);

    const getExperiencesFromAPI = async () => {
        _dispatch(loadingActions.enableLoading);
        const response = await ExperiencesService.getExperiencesFromAPI();
        if (response.status === 200){
            setExperiences(response.data.items);
        }
        else{
            console.error("Could not retrieve experiences");
        }
        _dispatch(loadingActions.disableLoading);
    };

    const onSelectExperience = (id) => (event) => {
        event.preventDefault();
        // alert(id);
        const expr = experiences.find(experience => experience.id === id);
        // console.log(proj);
        setSelectedExperience(expr);
        console.log(selectedExperience);
    };

    const isExperienceSelected = () => {
        if (selectedExperience){
            return (
                <div>
                    <Heading size="sm">Selected Experience</Heading>
                    <Divider className="experience__selected_experience_divider"/>
                    <FormLabel>Experience Title</FormLabel>
                    <Box d="flex" alignItems="center" bg="gray.100" w="100%" p={4} borderRadius="lg" fontsiz="sm"
                         style={{"marginBottom": "0.2rem", "display": "flex", "justifyContent": "space-between"}}>
                        {selectedExperience.title}
                    </Box>

                    <FormLabel>Experience ID</FormLabel>
                    <Box d="flex" alignItems="center" bg="gray.100" w="100%" p={4} borderRadius="lg" fontsiz="sm"
                         style={{"marginBottom": "0.2rem", "display": "flex", "justifyContent": "space-between"}}>
                        {selectedExperience.id}
                    </Box>

                    <FormLabel>Company</FormLabel>
                    <Box d="flex" alignItems="center" bg="gray.100" w="100%" p={4} borderRadius="lg" fontsiz="sm"
                         style={{"marginBottom": "0.2rem", "display": "flex", "justifyContent": "space-between"}}>
                        {selectedExperience.company}
                    </Box>

                    <FormLabel>Location</FormLabel>
                    <Box d="flex" alignItems="center" bg="gray.100" w="100%" p={4} borderRadius="lg" fontsiz="sm"
                         style={{"marginBottom": "0.2rem", "display": "flex", "justifyContent": "space-between"}}>
                        {selectedExperience.location}
                    </Box>

                    <FormLabel> Description</FormLabel>
                    {selectedExperience.description.map( (descriptionLine, index) => {
                        if (descriptionLine !== ""){ // don't render empty description lines (should ideally be none in the first place)
                            return (
                                <Box key={index} d="flex" alignItems="center" bg="gray.100" w="100%" p={4} borderRadius="lg" fontsiz="sm"
                                     style={{"marginBottom": "0.2rem", "display": "flex", "justifyContent": "space-between"}}>
                                    {descriptionLine}
                                </Box>
                            )
                        }
                    })}

                </div>
            );
        }
    };
    return (
        <div>
            <Banner heading='Experiences'>
                <Button leftIcon={<AddIcon />}
                        colorScheme="teal"
                        variant="solid"
                        onClick={() => history.push('experiences/new')}>
                    New Experience
                </Button>
            </Banner>

            {/* Dispaly list of experiences */}

            <div className="experiences__content-body">
                <div className="experiences__content-body-left">
                    <Table variant="simple" >
                        <Thead className="experiences__table_head">
                            <Tr>
                                <Th>Title</Th>
                                <Th>Company</Th>
                                <Th>Description</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {experiences.map(experience => {
                                return (
                                    <Tr className={experience.id === selectedExperience?.id ? "experiences__table_row_selected" : "experiences__table_row"}
                                        key={experience.id} onClick={onSelectExperience(experience.id)}>
                                        <Td> {experience.title}</Td>
                                        <Td> {experience.company}</Td>
                                        <Td>{experience.description[0].substr(0, 15)}...</Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Th>Title</Th>
                                <Th>Company</Th>
                                <Th >Description by</Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </div>

                <div className="experiences__content-body-right">
                  {isExperienceSelected()}
                </div>
            </div>
        </div>
    )
};

export default ExperiencesView;