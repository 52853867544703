import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import QualificationsService from "src/services/QualificationsService";

import {Button, FormControl, FormLabel, Textarea, Input, Tooltip} from '@chakra-ui/react';
import {AddIcon, ArrowBackIcon, InfoOutlineIcon} from "@chakra-ui/icons";
import Banner from "../../shared/UIShell/Banner/Banner";

import SortableList from "src/components/shared/SortableList/SortableList";
import DynamicInput from "src/components/shared/DynamicInput/DynamicInput";
import {v4 as uuidv4} from "uuid";

import './QualificationsView.scss';

const QualificationsNewView = () => {
    const history = useHistory();
    const [descriptionState, setDescription] = useState([{uuid: uuidv4(), content: '', id: 1}]);


    const init_state = {
        school: "",
        program: "",
        gpa: "",
        description: ""
    };
    const [newQualification, setNewQualification] = useState(init_state);

    const postQualificationsFromAPI = async (event) => {
        event.preventDefault();

        const response = await QualificationsService.postQualificationsFromAPI(newQualification);
        if (response.status === 201){
            setNewQualification(init_state);
            history.push('/dashboard/qualifications');
        }
        else{
            console.error("Could not create a new qualification.");
        }
    };

    return (

        <div>
            <Banner heading='Create a new Qualification'>
                <Button leftIcon={<ArrowBackIcon />}
                        variant="solid"
                        onClick={() => history.goBack()}  >   Back To Qualifications
                </Button>
            </Banner>

            <div className="qualifications__content-body">
                <div className="qualifications__content-body-left">
                    <form onSubmit={postQualificationsFromAPI}>
                        <FormControl id="qualification" >
                            <FormLabel> School</FormLabel>
                            <Input type="text"
                                   placeholder="Enter school name"
                                   value={newQualification.school}
                                   onChange={(e) => {
                                      setNewQualification({ ...newQualification, school: e.target.value });
                                   }
                                   }
                            />

                            <FormLabel> Program</FormLabel>
                            <Input type="text"
                                   placeholder="Enter Program Name"
                                   value={newQualification.program}
                                   onChange={(e) => {
                                       setNewQualification({ ...newQualification, program: e.target.value });
                                   }
                                   }
                            />

                            <FormLabel> GPA </FormLabel>
                            <Input type="text"
                                   placeholder="Enter GPA"
                                   value={newQualification.location}
                                   onChange={(e) => {
                                       setNewQualification({ ...newQualification, gpa: e.target.value });
                                   }
                                   }
                            />

                            <FormLabel> Description</FormLabel>
                            <DynamicInput parentState={descriptionState} onChange={(newDescription) => setDescription(newDescription)}/>

                            <Button type="submit" isFullWidth={true} colorScheme="teal">Create Qualification</Button>
                        </FormControl>
                    </form>
                </div>
                <div className="qualifications__content-body-right">
                    <span className="qualifications__ordering_preview_container">
                        <FormLabel>Ordering Preview</FormLabel>
                        <Tooltip label="Drag to reorder description lines. Animations may not work yet." fontSize="sm">
                            <InfoOutlineIcon />
                        </Tooltip>
                    </span>
                    <SortableList parentState={descriptionState} onChange={(newDescription) => setDescription(newDescription)} />
                </div>
            </div>
        </div>


        //
        // {/*<div>*/}
        // {/*    <button*/}
        // {/*        onClick={() => history.goBack()}*/}
        // {/*    >*/}
        // {/*        Back*/}
        // {/*    </button>*/}
        //
        //
        // {/*    <form>*/}
        // {/*        <h2> New Qualification</h2>*/}
        //
        // {/*        <label>School Name</label>*/}
        // {/*        <br></br>*/}
        // {/*        <input*/}
        // {/*            type="text"*/}
        // {/*            placeholder="Enter school name"*/}
        // {/*            value={newQualification.school}*/}
        // {/*            onChange={ (e) => setNewQualification({...newQualification, school:e.target.value}) }*/}
        // {/*        />*/}
        // {/*        <br></br>*/}
        // {/*        <br></br>*/}
        //
        // {/*        <label>Program Name</label>*/}
        // {/*        <br></br>*/}
        // {/*        <input*/}
        // {/*            type="text"*/}
        // {/*            placeholder="Enter program name"*/}
        // {/*            value={newQualification.program}*/}
        // {/*            onChange={(e) => setNewQualification({ ...newQualification, program:e.target.value})}*/}
        // {/*        />*/}
        // {/*        <br></br>*/}
        // {/*        <br></br>*/}
        //
        // {/*        <label>Enter GPA</label>*/}
        // {/*        <br></br>*/}
        // {/*        <input*/}
        // {/*            type="text"*/}
        // {/*            placeholder="Enter gpa"*/}
        // {/*            value={newQualification.gpa}*/}
        // {/*            onChange={ (e) => setNewQualification({...newQualification, gpa:e.target.value}) }*/}
        // {/*        />*/}
        // {/*        <br></br>*/}
        // {/*        <br></br>*/}
        //
        // {/*        <label>Description</label>*/}
        // {/*        <br></br>*/}
        // {/*        <textarea*/}
        // {/*            placeholder="Description"*/}
        // {/*            value={newQualification.description}*/}
        // {/*            onChange={ (e) => setNewQualification({...newQualification, description:e.target.value}) }*/}
        // {/*        />*/}
        // {/*        <br></br>*/}
        // {/*        <br></br>*/}
        //
        // {/*        <button type="submit" onClick={postQualificationsFromAPI}> Submit Qualification</button>*/}
        //
        // {/*    </form>*/}
        // {/*</div>*/}

    )
};

export default QualificationsNewView;