import axios from "../localaxios";


const ExperiencesService = {
    getExperiencesFromAPI: () => {
        return axios({
            url: '/experiences',
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                //  console.log(response);
                return response;

            })
            .catch(function (error) {
                return error.response;
            });
    },

    postExperiencesFromAPI: (newExperience) => {
        return axios({
            url: '/experiences',
            method: 'POST',
            data: newExperience,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                return response;

            })
            .catch(function (error) {
                return error.response;
            });
    }
};

export default ExperiencesService;