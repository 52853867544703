import React, {useState, useEffect, useLayoutEffect} from 'react';
import QualificationsService from "src/services/QualificationsService";
import {useDispatch, useSelector} from 'react-redux';
import loadingActions from "src/redux/loading/loadingActions";
import {useHistory} from "react-router-dom";
import {Box, Button, Divider, FormLabel, Heading} from "@chakra-ui/react";
import {AddIcon} from "@chakra-ui/icons";
import Banner from "../../shared/UIShell/Banner/Banner";
import {Table, Thead, Tfoot, Th, Tr, Td, TableCaption, Tbody} from '@chakra-ui/react';
import './QualificationsView.scss'


const QualificationsView = (props) => {
    const _dispatch = useDispatch();
    const history = useHistory();

    const [qualifications, setQualifications] = useState([]);
    const [selectedQualification, setSelectedQualification] = useState(null);

    useLayoutEffect(() => {
        getQualificationsFromAPI();
    }, []);

    const getQualificationsFromAPI = async () => {
        _dispatch(loadingActions.enableLoading);
        const response = await QualificationsService.getQualificationsFromAPI();
        if (response.status === 200){
            console.log(response);
            setQualifications(response.data.items);
        }
        else{
            console.error("Could not retrieve qualifications");
        }
        _dispatch(loadingActions.disableLoading);
    };

    const onSelectQualification = (id) => (event) => {
        event.preventDefault();
        const qual = qualifications.find( qualification => qualification.id === id);
        setSelectedQualification(qual);
    };

    const isQualificationSelected = () => {
        if(selectedQualification) {
            return (
                <div>
                    <Heading size="sm"> Selected Qualification</Heading>
                    <Divider className="qualification__selected_qualification_divider"/>
                    <FormLabel> School Name </FormLabel>
                    <Box d="flex" alignItems="center" bg="gray.100" w="100%" p={4} borderRadius="lg" fontsiz="sm"
                         style={{"marginBottom": "0.2rem", "display": "flex", "justifyContent": "space-between"}}>
                        {selectedQualification.school}
                    </Box>


                    <FormLabel> Program Name </FormLabel>
                    <Box d="flex" alignItems="center" bg="gray.100" w="100%" p={4} borderRadius="lg" fontsiz="sm"
                         style={{"marginBottom": "0.2rem", "display": "flex", "justifyContent": "space-between"}}>
                        {selectedQualification.program}
                    </Box>

                    <FormLabel> Program ID </FormLabel>
                    <Box d="flex" alignItems="center" bg="gray.100" w="100%" p={4} borderRadius="lg" fontsiz="sm"
                         style={{"marginBottom": "0.2rem", "display": "flex", "justifyContent": "space-between"}}>
                        {selectedQualification.id}
                    </Box>


                    <FormLabel> GPA </FormLabel>
                    <Box d="flex" alignItems="center" bg="gray.100" w="100%" p={4} borderRadius="lg" fontsiz="sm"
                         style={{"marginBottom": "0.2rem", "display": "flex", "justifyContent": "space-between"}}>
                        {selectedQualification.gpa}
                    </Box>
                </div>
            )
        }
    }




    return (
        <div className="qualifications">
            <Banner heading='Qualifications'>
                <Button leftIcon={<AddIcon />}
                        colorScheme="teal"
                        variant="solid"
                        onClick={() => history.push('qualifications/new')}>
                    New Qualifications
                </Button>
            </Banner>

            {/* Dispaly list of qualifications */}

            <div className="qualifications__content-body">
                <div className="qualifications__content-body-left">
                    <Table variant="simple" >
                        <Thead className="qualifications__table_head">
                            <Tr>
                                <Th>School</Th>
                                <Th>Program</Th>
                                <Th >GPA</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {qualifications.map(qualification => {
                                return (
                                    <Tr className={qualification.id === selectedQualification?.id ? "qualifications__table_row_selected" : "qualifications__table_row"}
                                        key={qualification.id} onClick={onSelectQualification(qualification.id)}>
                                        <Td> {qualification.school} </Td>
                                        <Td> {qualification.program} </Td>
                                        <Td> {qualification.gpa} </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Th>School</Th>
                                <Th>Program</Th>
                                <Th >GPA</Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </div>

                <div className="qualifications__content-body-right">
                    {isQualificationSelected()}
                </div>
            </div>
        </div>
    )
};

export default QualificationsView;