import React from 'react';
import '../../css/Header.css';
// import SearchIcon from "@material-ui/icons/Search";
// import { Avatar } from "@material-ui/core";
// import Dropdown from 'react-bootstrap/Dropdown';
// import DropdownButton from 'react-bootstrap/DropdownButton';
import axios from 'axios';
import { connect } from 'react-redux';
import * as authActions from '../../redux/auth/authActions';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import LoginService from "src/services/LoginService";


function Header(props) {
    let history = useHistory();

    const logout = (event) => {
        event.preventDefault();
        axios({
            url: 'http://localhost:5000/api/v1.0/tokens', 
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.token}`
            }})
          .then(function (response) {
            console.log(response);
            history.push('/login');
          })
          .catch(function (error) {
            history.push('/login');
            console.log(error);
          });

    };

    return (
        <div className='header'>

        </div>
    )
}

// const mapStateToProps = (state) => {
//     return {
//         token: state.token
//     }
// }
//
// const mapDispatchToProps = dispatch => {
//     return {
//         saveToken: (_token) => dispatch(authActions.saveToken(_token))
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Header);

export default Header;