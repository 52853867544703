

import RegisterView from "src/components/views/Register/RegisterView";
import LoginView from "src/components/views/Login/LoginView";



export const auth_routes = [
    {
        path: "/register",
        name: "Register",
        component: <RegisterView/>,
        layout: "/auth"
    },
    {
        path: "/login",
        name: "Login",
        component: <LoginView/>,
        layout: "/auth"
    },
    { // important that this is last from all
        path: "",
        name: "Login",
        component: <LoginView/>,
        layout: ""
    },

];

